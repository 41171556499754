/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, NavLink } from 'react-router-dom';
import { useAuth, logout } from 'context/auth/AuthState';
import Logo from '../ui/Logo';

const userNavigation = [
	// { name: 'Your Profile', href: '/app/settings?tab=0' },
	{ name: 'Password Settings', href: '/app/settings?tab=1' },
	{ name: 'Sign out' },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const DashboardNavigation = () => {
	const [currentUser, setCurrentUser] = useState({
		first_name: 'Tom',
		last_name: 'Cook',
		email: 'tom@example.com',
		profileImage:
			'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
	});
	const [authState, authDispatch] = useAuth();
	const { user } = authState;

	const navigation = [
		{ name: 'Dashboard', href: '/app/dashboard' },
		{ name: 'Customers', href: '/app/customers' },
	];

	useEffect(() => {
		if (user?.email) setCurrentUser(user);
	}, [user]);

	console.log(user);

	return (
		<Disclosure as='nav' className='bg-primary-500'>
			{({ open }) => (
				<>
					<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
						<div className='flex items-center justify-between h-16'>
							<div className='flex items-center'>
								<div className='flex-shrink-0'>
									<Logo className='!w-10 !h-10' link='/app/dashboard' />
								</div>

								<div className='hidden md:block'>
									<div className='flex items-baseline ml-10 space-x-4'>
										{navigation.map((item, index) => {
											if (user?.is_admin == 0 && item.name === 'Customers') {
												return <div></div>;
											}

											return (
												<NavLink
													key={index}
													to={item.href}
													className={({ isActive }) =>
														`px-4 lg:px-0 lg:mr-4 py-[18px] duration-300 hover:border-white text-base font-medium border-b-4 border-transparent ${
															isActive
																? '!border-gold-500 text-gold-500 font-semibold'
																: 'text-gray-300 hover:text-white'
														}`
													}
													// aria-current={ ? 'page' : undefined}
												>
													{item.name}
												</NavLink>
											);
										})}
									</div>
								</div>
							</div>

							<div className='hidden md:block'>
								<div className='flex items-center ml-4 md:ml-6'>
									{/* <button
										type='button'
										className='p-1 duration-500 bg-white rounded-full text-primary-500 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gold-500 focus:ring-offset-gray-800 focus:ring-white'
									>
										<span className='sr-only'>View notifications</span>
										<BellIcon className='w-6 h-6' aria-hidden='true' />
									</button> */}

									{/* Profile dropdown */}
									<Menu as='div' className='relative ml-4'>
										<div>
											<Menu.Button className='flex items-center max-w-xs text-sm rounded-full focus:outline-none bg-primary-800 ring-2 ring-offset-4 ring-offset-primary-500 focus:ring-gold-500 ring-white'>
												<span className='sr-only'>Open user menu</span>
												{currentUser?.profileImage ? (
													<img
														className='w-10 h-10 rounded-full'
														src={currentUser?.profileImage}
														alt=''
													/>
												) : (
													<div className='flex items-center justify-center w-10 h-10 text-2xl font-semibold text-white rounded-full'>
														{currentUser?.first_name.charAt(0)}
													</div>
												)}
											</Menu.Button>
										</div>

										<Transition
											as={Fragment}
											enter='transition ease-out duration-100'
											enterFrom='transform opacity-0 scale-95'
											enterTo='transform opacity-100 scale-100'
											leave='transition ease-in duration-75'
											leaveFrom='transform opacity-100 scale-100'
											leaveTo='transform opacity-0 scale-95'
										>
											<Menu.Items className='absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
												{userNavigation.map((item) => {
													const { name, href } = item;

													if (href) {
														return (
															<Menu.Item key={name}>
																<Link to={href ?? '/app/dashboard'}>
																	<span className='inline-block px-3 py-1'>
																		{name}
																	</span>
																</Link>
															</Menu.Item>
														);
													} else {
														return (
															<Menu.Item
																as={'button'}
																key={name}
																onClick={() => logout(authDispatch)}
															>
																<span className='inline-block px-3 py-1'>
																	{name}
																</span>
															</Menu.Item>
														);
													}
												})}
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>

							<div className='flex -mr-2 md:hidden'>
								{/* Mobile menu button */}
								<Disclosure.Button className='inline-flex items-center justify-center p-2 duration-300 bg-white rounded-full text-primary-500 hover:text-white hover:bg-gold-500 focus:outline-none'>
									<span className='sr-only'>Open main menu</span>
									{open ? (
										<XIcon className='block w-6 h-6' aria-hidden='true' />
									) : (
										<MenuIcon className='block w-6 h-6' aria-hidden='true' />
									)}
								</Disclosure.Button>
							</div>
						</div>
					</div>

					<Disclosure.Panel className='md:hidden'>
						{user?.is_admin != 0 && (
							<div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
								{navigation.map((item) => (
									<Disclosure.Button
										key={item.name}
										as={Link}
										to={item?.href ?? '/app/dashboard'}
										className={classNames(
											item.current
												? 'bg-gold-500 font-semibold text-primary-500'
												: 'text-gray-300 hover:bg-gray-700 hover:text-white',
											'block px-3 py-2 rounded-md duration-300 text-base font-medium'
										)}
										aria-current={item.current ? 'page' : undefined}
									>
										{item.name}
									</Disclosure.Button>
								))}
							</div>
						)}

						<div className='pt-4 pb-3 border-t border-gray-700'>
							<div className='flex items-center px-5'>
								<div className='flex-shrink-0'>
									{currentUser?.profileImage ? (
										<img
											className='w-10 h-10 rounded-full bg-primary-300'
											src={currentUser.profileImage}
											alt=''
										/>
									) : (
										<div className='w-10 h-10 rounded-full bg-primary-300'>
											{currentUser?.first_name.charAt(0)}
										</div>
									)}
								</div>

								<div className='ml-3'>
									<div className='text-base font-medium leading-none text-white'>
										{currentUser?.first_name} {currentUser.last_name}
									</div>

									<div className='text-sm font-medium leading-none text-gray-400'>
										{currentUser.email}
									</div>
								</div>

								{/* <button
									type='button'
									className='p-1 ml-auto duration-500 bg-white rounded-full text-primary-500 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gold-500 focus:ring-offset-gray-800 focus:ring-white'
								>
									<span className='sr-only'>View notifications</span>
									<BellIcon className='w-6 h-6' aria-hidden='true' />
								</button> */}
							</div>

							{/* {user?.is_admin != 0 && ( */}
							<div className='px-2 mt-3 space-y-1'>
								{userNavigation.map((item) => {
									if (user?.is_admin == 0 && item.name === 'Customers') {
										return <></>;
									}

									return (
										<Disclosure.Button
											key={item.name}
											as={Link}
											to={item?.href ?? '/app/dashboard'}
											className='block px-3 py-2 text-base font-medium text-gray-400 rounded-md hover:text-white hover:bg-gray-700'
										>
											{item.name}
										</Disclosure.Button>
									);
								})}
							</div>
							{/* )} */}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default DashboardNavigation;
